import { useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import { useSelector } from "../../../store/hooks";

const defaultSchemaObj = (isStockValuesFieldExcluded: boolean) => {
  return {
    currencyCode: yup.mixed().required(),
    buildingValues: yup.string().required(),
    equipmentValues: yup.string().required(),

    ...(!isStockValuesFieldExcluded && {
      stockValues: yup.string().required(),
    }),

    totalPd: yup.string().required(),
    totalPdBi: yup.string().required(),
    indemnityPeriodCode: yup.mixed().required(),
    businessInteruptionValuesBasedOnIndemnityPeriod: yup.string().required(),
    totalPdBiBasedOnIndemnityPeriod: yup.string().required(),
    //clientComment: yup.string().required(),
    coveragePeriodFromDate: yup.date().required(),
    coveragePeriodToDate: yup.date().required(),
    desiredLocationOwningStatusCode: yup.mixed().required(),
    desiredLocationStatusCode: yup.mixed().required(),
    desiredLocationOwningStatusDate: yup.date().required(),
    desiredLocationStatusDate: yup.date().required(),
  };
};

function useValidationSchema() {
  const clientInternalNumber = useSelector(
    (s) => s.valueMonitoring.selectedLocation?.client?.clientInternalNumber
  );
  const stockValuesExcludedClients =
    process.env.REACT_APP_EXCLUDE_STOCKVALUES_FIELD_CLIENTS;

  const [validationSchemaObj, setValidationSchemaObject] = useState(null);

  const isStockValuesFieldExcluded = useMemo(() => {
    const retVal = stockValuesExcludedClients
      ? stockValuesExcludedClients
          ?.split(",")
          .includes(clientInternalNumber + "")
      : true;
    setValidationSchemaObject(defaultSchemaObj(retVal));
    return retVal;
  }, [clientInternalNumber, stockValuesExcludedClients]);

  const handleUpdateSchema = useCallback((addedSchemaObj) => {
    setValidationSchemaObject((prev) => {
      return {
        ...prev,
        ...addedSchemaObj,
      };
    });
  }, []);

  const validationSchema = yup.object().shape(validationSchemaObj);
  return {
    validationSchema,
    handleUpdateSchema,
  };
}

export default useValidationSchema;
