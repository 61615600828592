import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import { DefaultButton } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IDocumentConfigurationSettings } from "../../../types/types";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store/hooks";
import { formBase64String } from "../../../utils/utils";
import { addDocument } from "../../../api/documentatioApi";
import { setDocumentConfiguration } from "../../../store/locations/locations";
import { toast } from "../../../components/FluentToast";

type Props = {
  isInternalUserDocument: boolean;
  handleSetConfigurationSettings: (
    data: IDocumentConfigurationSettings
  ) => void;
};

const DocumentDropzone = ({
  handleSetConfigurationSettings,
  isInternalUserDocument,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dataLoading, setDataLoading] = useState(false);

  const clientId = useSelector((s) => s.clients.clientId);

  const formatFile = async (file) => {
    if (!file) return;
    const data = await {
      name: file.name,
      base64String: await formBase64String(file),
    };
    return data;
  };
  const handleAddNewDocument = async (file) => {
    try {
      setDataLoading(true);
      const payloadFile = await formatFile(file);
      const response = await addDocument({
        clientId,
        isInternalUserDocument: isInternalUserDocument,
        data: payloadFile,
      });

      const data = response
        ? {
            ...response,
            settingValue: JSON.parse(response.settingValue),
          }
        : null;

      handleSetConfigurationSettings(data);
      dispatch(
        setDocumentConfiguration(data.settingValue.InternalUserDocuments)
      );
    } catch (error: any) {
      toast.error(error?.message);
      setDataLoading(false);
    } finally {
      setDataLoading(false);
    }
  };
  const handleSubmit = async (e, allFiles) => {
    console.log(allFiles.map((f) => f.meta));
    await handleAddNewDocument(allFiles[0].file);

    allFiles.forEach((f) => f.remove());
  };

  const CustomSubmitButton = ({ onSubmit, files, disabled }) => {
    const { t } = useTranslation();
    return (
      <DefaultButton
        onClick={onSubmit}
        disabled={disabled || dataLoading || files.length === 0}
        styles={{
          root: {
            marginTop: 10,
            backgroundColor: "#005aa1",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            fontSize: "16px",
            fontWeight: "600",
            cursor: "pointer",
          },
          rootDisabled: {
            backgroundColor: "#f3f2f1",
            color: "#a19f9d",
            cursor: "not-allowed",
          },
        }}
      >
        {t("rvm.addDocument.label")}
      </DefaultButton>
    );
  };

  return (
    <>
      <Dropzone
        onSubmit={handleSubmit}
        maxFiles={1}
        inputContent={t("rvm.dragFileHereOrClick")}
        styles={{
          dropzone: {
            width: 600,
            height: 200,
            border: "2px dashed #aaa",
            borderRadius: "8px",
            padding: "20px",
            textAlign: "center",
          },
          inputLabel: { fontSize: "18px", fontWeight: "600", color: "#808080" },
        }}
        SubmitButtonComponent={CustomSubmitButton}
      />
    </>
  );
};

export default DocumentDropzone;
