import { mergeStyleSets } from "@fluentui/react";
import { useFormikContext } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../ReadOnly.scss";
import Dropzone from "../../../components/Dropzone";
import ComboBoxField from "../../../components/controls/ComboBoxField";
import DatePickerField from "../../../components/controls/DatePickerField";
import DoubleNumberInputField from "../../../components/controls/DoubleNumberInputField";
import { useSelector } from "../../../store/hooks";

import * as yup from "yup";
import { toast } from "../../../components/FluentToast";
import { CreatebleDropdown } from "../../../components/controls/CreatableDropdown";
import TextInputField from "../../../components/controls/TextInputField";
import useIsExternalUser from "../../../hooks/useIsExternalUser";
import useIsInternalUser from "../../../hooks/useIsInternalUser";
import { useBusinessInteruptionValuesBasedOnPi } from "../hooks/useBusinessInteruptionValuesBasedOnPi";
import useFormFieldsOptions from "../hooks/useFormFieldsOptions";
import { useTotalPdBiBasedOnIndemnityPeriod } from "../hooks/useTotalPdBiBasedOnIndemnityPeriod";
import { useTotalPdBiField } from "../hooks/useTotalPdBiField";
import { useTotalPdField } from "../hooks/useTotalPdField";

import { stringToNumber } from "../../../utils/utils";
import {
  changeValuesFields,
  finallyClosedTaxonomyValue,
  getEnteredValuesChangesConfig,
  notExistingAnymoreTaxonomyValue,
  soldTaxonomyValue,
} from "./constants";
import useSetInitialFormValues from "./hooks/useSetInitialFormValues";
import { EValueChangesStatus } from "./types";
import { getDifferenceInPercent } from "./utils";

const PropertyInfoForm = ({
  formikProps,
  file,
  setFile,
  filename,
  setFilename,
  handleUpdateSchema,
  formResetState,
}) => {
  useSetInitialFormValues();

  const { t } = useTranslation();
  const enteredValuesChangesConfig = getEnteredValuesChangesConfig(t);
  const histories = useSelector((s) => s.valueMonitoring.historyData);
  const historyData = useMemo(() => {
    if (!histories || histories.length === 1) return null;

    return histories[1];
  }, [histories]);

  const isExternalUser = useIsExternalUser();
  const isInternalUser = useIsInternalUser();

  const clientInternalNumber = useSelector(
    (s) => s.valueMonitoring.selectedLocation?.client?.clientInternalNumber
  );
  const stockValuesExcludedClients =
    process.env.REACT_APP_EXCLUDE_STOCKVALUES_FIELD_CLIENTS;

  const isStockValuesFieldExcluded = useMemo(() => {
    const retVal = stockValuesExcludedClients
      ? stockValuesExcludedClients
          ?.split(",")
          .includes(clientInternalNumber + "")
      : true;

    return retVal;
  }, [clientInternalNumber, stockValuesExcludedClients]);

  const defaultValueChangesState = {
    buildings: {
      displayDropdown: false,
      displayInput: true,
      status: "",

      Decreased: {
        options: enteredValuesChangesConfig.buildings.decreased.options,
        message: enteredValuesChangesConfig.buildings.decreased.message,
      },
      Increased: {
        options: enteredValuesChangesConfig.buildings.increased.options,
        message: enteredValuesChangesConfig.buildings.increased.message,
      },
    },
    equipment: {
      displayDropdown: false,
      displayInput: true,
      status: "",
      Decreased: {
        options: enteredValuesChangesConfig.equipment.decreased.options,
        message: enteredValuesChangesConfig.equipment.decreased.message,
      },
      Increased: {
        options: enteredValuesChangesConfig.equipment.increased.options,
        message: enteredValuesChangesConfig.equipment.increased.message,
      },
    },
    businessInterruption: {
      displayDropdown: false,
      displayInput: true,
      status: "",
      Decreased: {
        options:
          enteredValuesChangesConfig.businessInterruption.decreased.options,
        message:
          enteredValuesChangesConfig.businessInterruption.decreased.message,
      },
      Increased: {
        options:
          enteredValuesChangesConfig.businessInterruption.increased.options,
        message:
          enteredValuesChangesConfig.businessInterruption.increased.message,
      },
    },

    locationStatus: {
      displayDropdown: false,
      displayInput: true,
      status: "",
      options: [],
      message: "",
    },
  };

  const [valueChangesState, setValueChangesState] = useState(
    defaultValueChangesState
  );

  const {
    values,
    setFieldValue,
    errors,
    handleChange,
  }: { values: any; setFieldValue; errors; handleChange; setValues } =
    useFormikContext();

  const isFinallyClosedLocationCode =
    values.desiredLocationStatusCode?.value === finallyClosedTaxonomyValue;

  const isSoldLocationCode =
    values.desiredLocationStatusCode?.value === soldTaxonomyValue;

  const isNotExistingLocationCode =
    values.desiredLocationStatusCode?.value === notExistingAnymoreTaxonomyValue;

  const taxonomy = useSelector((s) => s.taxonomy);
  const formObject = useSelector((s) => s.valueMonitoring.formObject);

  const {
    currencyCodeOptions,
    locationOwningStatusCodeOptions,
    locationStatusCodeOptions,
    indemnityCodeOptions,
  } = useFormFieldsOptions();

  useTotalPdField();
  useTotalPdBiField();
  useBusinessInteruptionValuesBasedOnPi();
  useTotalPdBiBasedOnIndemnityPeriod();

  const isInvitationSent = formObject
    ? taxonomy.PropertyInformationStatus.byId[formObject.statusCode].code ===
      "PropertyInformationStatus.INVITATION_SENT"
    : false;

  const locationFieldsDisabled: boolean = useMemo(() => {
    if (!formObject || typeof formObject === "undefined") return false;
    if (
      isInternalUser &&
      taxonomy?.PropertyInformationStatus.byId[formObject.statusCode].code !==
        "PropertyInformationStatus.REVIEW"
    ) {
      return true;
    } else {
      return false;
    }
  }, [formObject, taxonomy, isInternalUser]);

  const handleValuesChanges = useCallback(
    (
      rawValue: string,
      historyValue: number | null,
      field: string,
      percentageLimit: number | null,
      commentField: string
    ) => {
      if (!rawValue) {
        setFieldValue(commentField, "");
        handleUpdateSchema({
          [commentField]: yup.mixed().nullable(),
        });
        return;
      }

      const currentValue = stringToNumber(rawValue);
      const percentDifference = getDifferenceInPercent(
        currentValue,
        historyValue
      );

      if (percentDifference === 0) {
        if (!isSoldLocationCode && !isNotExistingLocationCode) {
          toast.info(enteredValuesChangesConfig.equalMessage);
        }

        setValueChangesState((prev) => ({
          ...prev,
          ...prev,
          [field]: {
            ...prev[field],
            displayDropdown: false,
            displayInput: false,
            status: "",
          },
        }));
        setFieldValue(commentField, "");
        handleUpdateSchema({
          [commentField]: yup.mixed().nullable(),
        });
        return;
      }

      if (percentDifference > percentageLimit) {
        setValueChangesState((prev) => ({
          ...prev,
          [field]: {
            ...prev[field],
            displayDropdown: true,
            displayInput: false,
            status: EValueChangesStatus.Increased,
          },
        }));
        if (!isSoldLocationCode && !isNotExistingLocationCode) {
          toast.info(valueChangesState[field].Increased.message);
        }
        handleUpdateSchema({
          [commentField]: yup.mixed().required(),
        });

        return;
      }

      if (percentDifference < -percentageLimit) {
        if (!isSoldLocationCode && !isNotExistingLocationCode) {
          toast.info(valueChangesState[field].Decreased.message);
        }

        setValueChangesState((prev) => ({
          ...prev,
          [field]: {
            ...prev[field],
            displayDropdown: true,
            displayInput: false,
            status: EValueChangesStatus.Decreased,
          },
        }));
        handleUpdateSchema({
          [commentField]: yup.mixed().required(),
        });
        return;
      }

      setValueChangesState((prev) => ({
        ...prev,
        ...prev,
        [field]: {
          ...prev[field],
          displayDropdown: false,
          displayInput: false,
          status: "",
        },
      }));
      setFieldValue(commentField, "");
      handleUpdateSchema({
        [commentField]: yup.mixed().nullable(),
      });
    },
    [
      enteredValuesChangesConfig.equalMessage,
      handleUpdateSchema,
      setFieldValue,
      valueChangesState,
      isSoldLocationCode,
      isNotExistingLocationCode,
    ]
  );

  const handleLocationStatusChange = useCallback(
    (desiredLocationStatusCode, fieldValue = null) => {
      const hasFinancialData =
        stringToNumber(values?.buildingValues) ||
        stringToNumber(values?.equipmentValues) ||
        (isStockValuesFieldExcluded
          ? false
          : stringToNumber(values?.stockValues)) ||
        stringToNumber(values?.businessInteruptionValues12Months) ||
        stringToNumber(fieldValue);

      const currentDesiredLocationStatusCode = desiredLocationStatusCode?.value;

      if (
        currentDesiredLocationStatusCode === finallyClosedTaxonomyValue &&
        hasFinancialData
      ) {
        toast.error(
          enteredValuesChangesConfig.finallyClosedLocationStatus.message
        );
        setValueChangesState((prev) => ({
          ...prev,
          ...prev,
          locationStatus: {
            ...prev["locationStatus"],
            message:
              enteredValuesChangesConfig.finallyClosedLocationStatus.message,
            options:
              enteredValuesChangesConfig.finallyClosedLocationStatus.options,
            displayDropdown: true,
            displayInput: false,
          },
        }));

        handleUpdateSchema({
          desiredLocationStatusCodeComment: yup.mixed().required(),
        });

        return;
      }
      if (
        currentDesiredLocationStatusCode === soldTaxonomyValue &&
        hasFinancialData
      ) {
        toast.error(enteredValuesChangesConfig.soldLocationStatus.message);

        return;
      }

      if (
        currentDesiredLocationStatusCode === notExistingAnymoreTaxonomyValue &&
        hasFinancialData
      ) {
        toast.error(
          enteredValuesChangesConfig.notExistingLocationStatus.message
        );

        return;
      }
      setValueChangesState((prev) => ({
        ...prev,
        ...prev,
        locationStatus: {
          displayDropdown: false,
          displayInput: false,
          status: "",
          options: [],
          message: "",
        },
      }));
      handleUpdateSchema({
        desiredLocationStatusCodeComment: yup.mixed().nullable(),
      });

      setFieldValue("desiredLocationStatusCodeComment", "");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      values?.buildingValues,
      values?.businessInteruptionValues12Months,
      values?.equipmentValues,
      values?.stockValues,
    ]
  );

  useEffect(() => {
    if (
      formObject.buildingValuesComment &&
      valueChangesState.buildings.displayInput
    ) {
      handleUpdateSchema({
        buildingValuesComment: yup.mixed().required(),
      });
    }
    if (
      formObject.equipmentValuesComment &&
      valueChangesState.equipment.displayInput
    ) {
      handleUpdateSchema({
        equipmentValuesComment: yup.mixed().required(),
      });
    }
    if (
      formObject.businessInteruptionValues12MonthsComment &&
      valueChangesState.businessInterruption.displayInput
    ) {
      handleUpdateSchema({
        businessInteruptionValues12MonthsComment: yup.mixed().required(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formObject]);

  useEffect(() => {
    setValueChangesState(defaultValueChangesState);
  }, [formResetState]);

  return (
    <>
      <div
        className={`corporate-form-Current corporate-form ${classes.containerOne}`}
      >
        <div className="grid-item0">
          <DatePickerField
            errors={errors}
            setFieldValue={setFieldValue}
            defaultValue={values.coveragePeriodFromDate}
            name={"coveragePeriodFromDate"}
            disabled={
              !(
                !formObject.coveragePeriodFromDate &&
                isExternalUser &&
                isInvitationSent
              )
            }
            label={"rvm.coveragePeriodFromDate.label"}
            placeholder={t("rvm.selectDate")}
            required
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("coveragePeriodFromDate")
            }
          />
        </div>
        <div className="grid-item1">
          <DatePickerField
            errors={errors}
            setFieldValue={setFieldValue}
            defaultValue={values.coveragePeriodToDate}
            name={"coveragePeriodToDate"}
            disabled={
              !(
                !formObject.coveragePeriodFromDate &&
                isExternalUser &&
                isInvitationSent
              )
            }
            label={"rvm.coveragePeriodToDate.label"}
            placeholder={t("rvm.selectDate")}
            required
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("coveragePeriodToDate")
            }
          />
        </div>
        <div className="grid-item2">
          <ComboBoxField
            disabled={locationFieldsDisabled}
            errors={errors}
            name="currencyCode"
            label={"rvm.currencyCode.label"}
            setFieldValue={setFieldValue}
            options={currencyCodeOptions || []}
            defaultValue={
              values.currencyCode !== null ? values.currencyCode : null
            }
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("currencyCode")
            }
            required
          />
        </div>
        <div className="grid-item3">
          <DoubleNumberInputField
            setFieldValue={setFieldValue}
            value={values.buildingValues}
            onChange={handleChange}
            handleBlur={(event) => {
              if (!historyData) return;

              const currentValue = event.target.value;

              const desiredLocationStatusCodeValue =
                values?.desiredLocationStatusCode?.value;

              if (
                desiredLocationStatusCodeValue === finallyClosedTaxonomyValue ||
                desiredLocationStatusCodeValue === soldTaxonomyValue
              ) {
                handleLocationStatusChange(
                  values.desiredLocationStatusCode,
                  currentValue
                );
              }

              handleValuesChanges(
                currentValue,
                historyData.buildingValues,
                changeValuesFields.buildings,
                10,
                changeValuesFields.buildingComment
              );
            }}
            errors={errors}
            name={"buildingValues"}
            disabled={false}
            label={"rvm.buildingValues.label"}
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("buildingValues")
            }
            required
          />
        </div>
        {valueChangesState.buildings.displayDropdown && (
          <div className="grid-item">
            <CreatebleDropdown
              key={valueChangesState.buildings.status}
              setFieldValue={setFieldValue}
              defaultOptions={
                valueChangesState.buildings[valueChangesState.buildings.status]
                  .options
              }
              name={`buildingValuesComment`}
              label={`rvm.buildingValuesComment.label`}
              error={errors[`buildingValuesComment`]}
            />
          </div>
        )}
        {formObject.buildingValuesComment &&
          valueChangesState.buildings.displayInput && (
            <TextInputField
              setFieldValue={setFieldValue}
              value={values.buildingValuesComment}
              onChange={handleChange}
              errors={errors}
              name={"buildingValuesComment"}
              disabled={false}
              label={"rvm.buildingValuesComment.label"}
            />
          )}
        <div className="grid-item4">
          <DoubleNumberInputField
            setFieldValue={setFieldValue}
            value={values.equipmentValues}
            onChange={handleChange}
            errors={errors}
            name={"equipmentValues"}
            handleBlur={(event) => {
              if (!historyData) return;
              const currentValue = event.target.value;
              const desiredLocationStatusCodeValue =
                values?.desiredLocationStatusCode?.value;

              if (
                desiredLocationStatusCodeValue === finallyClosedTaxonomyValue ||
                desiredLocationStatusCodeValue === soldTaxonomyValue ||
                desiredLocationStatusCodeValue ===
                  notExistingAnymoreTaxonomyValue
              ) {
                handleLocationStatusChange(
                  values.desiredLocationStatusCode,
                  currentValue
                );
              }

              handleValuesChanges(
                currentValue,
                historyData.equipmentValues,
                changeValuesFields.equipment,
                10,
                changeValuesFields.equipmentComment
              );
            }}
            disabled={false}
            label={"rvm.equipmentValues.label"}
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("equipmentValues")
            }
            required
          />
        </div>
        {valueChangesState.equipment.displayDropdown && (
          <div className="grid-item">
            <CreatebleDropdown
              key={valueChangesState.equipment.status}
              setFieldValue={setFieldValue}
              defaultOptions={
                valueChangesState.equipment[valueChangesState.equipment.status]
                  .options
              }
              name={`equipmentValuesComment`}
              label={"rvm.equipmentValuesComment.label"}
              error={errors[`equipmentValuesComment`]}
            />
          </div>
        )}

        {formObject.equipmentValuesComment &&
          valueChangesState.equipment.displayInput && (
            <TextInputField
              setFieldValue={setFieldValue}
              value={values.equipmentValuesComment}
              onChange={handleChange}
              errors={errors}
              name={"equipmentValuesComment"}
              disabled={false}
              label={"rvm.equipmentValuesComment.label"}
            />
          )}
        {isStockValuesFieldExcluded ? (
          <div className="grid-item5"></div>
        ) : (
          <div className="grid-item5">
            <DoubleNumberInputField
              setFieldValue={setFieldValue}
              value={values.stockValues}
              handleBlur={(event) => {
                if (!historyData) return;
                const currentValue = event.target.value;

                const desiredLocationStatusCodeValue =
                  values.desiredLocationStatusCode.value;

                if (
                  desiredLocationStatusCodeValue ===
                    finallyClosedTaxonomyValue ||
                  desiredLocationStatusCodeValue === soldTaxonomyValue ||
                  desiredLocationStatusCodeValue ===
                    notExistingAnymoreTaxonomyValue
                ) {
                  handleLocationStatusChange(
                    values.desiredLocationStatusCode,
                    currentValue
                  );
                }
              }}
              onChange={handleChange}
              errors={errors}
              name={"stockValues"}
              disabled={false}
              label={"rvm.stockValues.label"}
              isMarked={
                formObject?.fieldsUpdatedByExternalUser &&
                formObject?.fieldsUpdatedByExternalUser
                  .split(",")
                  .includes("stockValues")
              }
              required
            />
          </div>
        )}
        <div className="grid-item6">
          <DoubleNumberInputField
            setFieldValue={setFieldValue}
            value={values.totalPd}
            onChange={handleChange}
            errors={errors}
            name={"totalPd"}
            disabled={true}
            label={"rvm.totalPd.label"}
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("totalPd")
            }
            required
          />
        </div>
        <div className="grid-item7">
          <DoubleNumberInputField
            setFieldValue={setFieldValue}
            value={values.businessInteruptionValues12Months}
            handleBlur={(event) => {
              if (!historyData) return;

              const currentValue = event.target.value;
              const desiredLocationStatusCodeValue =
                values.desiredLocationStatusCode.value;

              if (
                desiredLocationStatusCodeValue === finallyClosedTaxonomyValue ||
                desiredLocationStatusCodeValue === soldTaxonomyValue ||
                desiredLocationStatusCodeValue ===
                  notExistingAnymoreTaxonomyValue
              ) {
                handleLocationStatusChange(
                  values.desiredLocationStatusCode,
                  currentValue
                );
              }
              handleValuesChanges(
                currentValue,
                historyData.businessInteruptionValues12Months,
                changeValuesFields.businessInterruption,
                20,
                changeValuesFields.businessInterruptionComment
              );
            }}
            onChange={handleChange}
            errors={errors}
            name={"businessInteruptionValues12Months"}
            disabled={false}
            label={"rvm.businessInteruptionValues12Months.label"}
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("businessInteruptionValues12Months")
            }
          />
        </div>
        {valueChangesState.businessInterruption.displayDropdown && (
          <div className="grid-item">
            <CreatebleDropdown
              key={valueChangesState.businessInterruption.status}
              setFieldValue={setFieldValue}
              defaultOptions={
                valueChangesState.businessInterruption[
                  valueChangesState.businessInterruption.status
                ].options
              }
              name={`businessInteruptionValues12MonthsComment`}
              label={"rvm.businessInteruptionValues12MonthsComment.label"}
              error={errors[`businessInteruptionValues12MonthsComment`]}
            />
          </div>
        )}
        {formObject.businessInteruptionValues12MonthsComment &&
          valueChangesState.businessInterruption.displayInput && (
            <TextInputField
              setFieldValue={setFieldValue}
              value={values.businessInteruptionValues12MonthsComment}
              onChange={handleChange}
              errors={errors}
              name={"businessInteruptionValues12MonthsComment"}
              disabled={false}
              label={"rvm.businessInteruptionValues12MonthsComment.label"}
            />
          )}
        <div className="grid-item8">
          <DoubleNumberInputField
            setFieldValue={setFieldValue}
            value={values.totalPdBi}
            onChange={handleChange}
            errors={errors}
            name={"totalPdBi"}
            disabled={true}
            label={"rvm.totalPdBi.label"}
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("totalPdBi")
            }
            required
          />
        </div>
        <div className="grid-item9">
          <ComboBoxField
            disabled={false}
            errors={errors}
            name="indemnityPeriodCode"
            label={"rvm.indemnityPeriodCode.label"}
            setFieldValue={setFieldValue}
            options={indemnityCodeOptions || []}
            defaultValue={
              values.indemnityPeriodCode !== null
                ? values.indemnityPeriodCode
                : null
            }
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("indemnityPeriodCode")
            }
            required
          />
        </div>
        <div className="grid-item10">
          <DoubleNumberInputField
            setFieldValue={setFieldValue}
            value={values.businessInteruptionValuesBasedOnIndemnityPeriod}
            onChange={handleChange}
            errors={errors}
            name={"businessInteruptionValuesBasedOnIndemnityPeriod"}
            disabled={true}
            label={"rvm.businessInteruptionValuesBasedOnIndemnityPeriod.label"}
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("businessInteruptionValuesBasedOnIndemnityPeriod")
            }
            required
          />
        </div>
        <div className="grid-item11">
          <DoubleNumberInputField
            setFieldValue={setFieldValue}
            value={values.totalPdBiBasedOnIndemnityPeriod}
            onChange={handleChange}
            errors={errors}
            name={"totalPdBiBasedOnIndemnityPeriod"}
            disabled={true}
            label={"rvm.totalPdBiBasedOnIndemnityPeriod.label"}
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("totalPdBiBasedOnIndemnityPeriod")
            }
            required
          />
        </div>

        <div className="grid-item12">
          <ComboBoxField
            disabled={locationFieldsDisabled}
            errors={errors}
            name="desiredLocationOwningStatusCode"
            label={"rvm.locationOwningStatusCode.label"}
            setFieldValue={setFieldValue}
            options={locationOwningStatusCodeOptions || []}
            defaultValue={
              values.desiredLocationOwningStatusCode !== null
                ? values.desiredLocationOwningStatusCode
                : null
            }
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("desiredLocationOwningStatusCode")
            }
            required
          />
        </div>
        <div className="grid-item13">
          <DatePickerField
            errors={errors}
            setFieldValue={setFieldValue}
            defaultValue={values.desiredLocationOwningStatusDate}
            name={"desiredLocationOwningStatusDate"}
            disabled={locationFieldsDisabled}
            label={"rvm.locationOwningStatusDate.label"}
            placeholder={t("rvm.selectDate")}
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("desiredLocationOwningStatusDate")
            }
            required
          />
        </div>
        <div className="grid-itemUpload">
          <div className={classes.uploadFile}>
            <Dropzone
              file={file}
              setFile={setFile}
              filename={filename}
              setFilename={setFilename}
              formikProps={formikProps}
            />
          </div>
        </div>
        <div className="grid-item14">
          <ComboBoxField
            handleChange={handleLocationStatusChange}
            disabled={locationFieldsDisabled}
            errors={errors}
            name="desiredLocationStatusCode"
            label={"rvm.locationStatusCode.label"}
            setFieldValue={setFieldValue}
            options={locationStatusCodeOptions || []}
            defaultValue={
              values.desiredLocationStatusCode !== null
                ? values.desiredLocationStatusCode
                : null
            }
            required
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("desiredLocationStatusCode")
            }
          />
        </div>
        {valueChangesState.locationStatus.displayDropdown && (
          <div className="grid-item">
            <CreatebleDropdown
              key={valueChangesState.locationStatus.options.length}
              setFieldValue={setFieldValue}
              defaultOptions={valueChangesState.locationStatus.options}
              name={`desiredLocationStatusCodeComment`}
              label={"rvm.locationStatusCodeComment.label"}
              error={errors[`desiredLocationStatusCodeComment`]}
            />
          </div>
        )}

        {formObject.desiredLocationStatusCodeComment &&
          valueChangesState.locationStatus.displayInput &&
          isFinallyClosedLocationCode && (
            <TextInputField
              setFieldValue={setFieldValue}
              value={values.desiredLocationStatusCodeComment}
              onChange={handleChange}
              errors={errors}
              name={"desiredLocationStatusCodeComment"}
              disabled={false}
              label={"rvm.locationStatusCodeComment.label"}
            />
          )}
        <div className="grid-item15">
          <DatePickerField
            errors={errors}
            setFieldValue={setFieldValue}
            defaultValue={values.desiredLocationStatusDate}
            name={"desiredLocationStatusDate"}
            disabled={locationFieldsDisabled}
            label={"rvm.locationStatusDate.label"}
            placeholder={t("rvm.selectDate")}
            isMarked={
              formObject?.fieldsUpdatedByExternalUser &&
              formObject?.fieldsUpdatedByExternalUser
                .split(",")
                .includes("desiredLocationStatusDate")
            }
            required
          />
        </div>
      </div>
    </>
  );
};

export default PropertyInfoForm;

const classes = mergeStyleSets({
  item: {
    padding: "20px",
    width: "49%",
    "@media(min-width: 1500px)": {
      width: "33%",
    },
  },
  uploadFile: {
    width: "100%",
  },
  containerOne: {
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
    gridColumnGap: "20px",
    gridRowGap: "20px",
    padding: "20px",
    paddingBottom: "30px",
    "& .grid-item0": {
      gridColumn: "1 / span 1",
    },
    "& .grid-item1": {
      gridColumn: "2 / span 1",
    },
    "& .grid-item2": {
      gridColumn: "3 / span 1",
    },
    "& .grid-itemUpload": {
      gridColumn: "3 / span 1",
      gridRow: "5 / span 2",
    },
  },
});
